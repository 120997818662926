import useClassNames from 'helpers/hooks/useClassNames';
import { ButtonProps, ButtonSize, ButtonVariant } from '.';

type variantBasedClassName = { [key in ButtonVariant]?: string };
type IncludesIcon = { includesIcon: boolean };
type UseButtonClassNames = (
  props: Pick<ButtonProps, 'variant' | 'size' | 'className' | 'loading' | 'updated' | 'asSkeleton'> & IncludesIcon,
) => string;

const useButtonClassNames: UseButtonClassNames = ({
  variant,
  className = '',
  includesIcon,
  loading,
  updated,
  asSkeleton = false,
  size,
}) => {
  const primaryClassName = useClassNames([
    'bg-neutral-6 text-neutral-5 rounded-[59px] justify-center items-center shrink-0',
    'hover:bg-neutral-4',
    'active:bg-neutral-4',
    'focus-visible:outline focus-visible:outline-gray-700 border-gray-700 focus-visible:border focus-visible:outline-offset-[3px]',
    'disabled:bg-neutral-2 disabled:text-neutral-3',
  ]);

  const primaryIconOnlyClassName = useClassNames([
    'bg-base-brand-1 rounded-[45px] items-center justify-center text-neutral-5',
    'active:bg-base-brand-2',
    'hover:bg-base-brand-2',
    'disabled:bg-neutral-2 disabled:text-neutral-3',
  ]);

  const secondaryClassName = useClassNames([
    'bg-neutral-5 text-neutral-4 rounded-[89px] border border-neutral-4',
    'hover:bg-neutral-1 hover:text-neutral-6 hover:rounded-[72px]',
    'active:bg-neutral-1 active:rounded-[72px]',
    'focus-visible:outline focus-visible:border-white-100 focus-visible:border focus-visible:outline-offset-[3px]',
    'disabled:text-neutral-3 disabled:!bg-neutral-1 disabled:rounded-[72px] disabled:border-neutral-3',
  ]);

  const secondaryIconOnlyClassName = useClassNames([
    'bg-neutral-5 text-neutral-4 rounded-[45px] border border-neutral-3 items-center justify-center',
    'hover:text-neutral-4 hover:bg-neutral-1',
    'active:bg-neutral-1',
    'disabled:text-neutral-3 disabled:bg-neutral-1 disabled:opacity-4 disabled:border-neutral-2',
  ]);

  const addToCartClassName = useClassNames([
    'bg-base-accent-1 rounded-r-[48px] text-neutral-5 items-center justify-center border border-base-accent-1',
    'hover:bg-[#0095FF] hover:border-[#0095FF]',
    'disabled:bg-base-accent-2 disabled:border-base-accent-2',
  ]);

  const removeFromCartClassName = useClassNames([
    'bg-neutral-5 rounded-l-[48px] border border-base-accent-2 text-base-accent-1 items-center justify-center',
    'hover:bg-base-accent-2',
    'disabled:bg-neutral-5',
  ]);

  const removedFromWishlistClassName = useClassNames([
    'bg-neutral-1 transition shrink-0 duration-150 ease-out rounded-full text-neutral-1 stroke-neutral-4',
  ]);

  const addedToWishlistClassName = useClassNames([
    'bg-base-accent-2 transition shrink-0 duration-150 ease-out rounded-full text-base-accent-1 stroke-base-accent-1',
  ]);

  const warningClassName = useClassNames([
    'bg-red-500 text-neutral-100 outline-offset-0',
    'hover:bg-red-600',
    'active:shadow-button active:disabled:shadow-none active:bg-red-500',
    'focus-visible:outline focus-visible:bg-red-700 focus-visible:outline-gray-700 focus-visible:border-red-700 focus-visible:border focus-visible:outline-offset-[3px]',
    'disabled:bg-neutral-400 disabled:text-neutral-100',
  ]);

  const underlineClassName = 'pb-2 text-gray-700 hover:underline disabled:text-neutral-400';

  const ghostClassName = 'disabled:text-neutral-400';

  const homeClassName = useClassNames([
    'bg-base-accent-2 shrink-0 rounded-full text-base-accent-4 stroke-base-accent-4',
  ]);

  const registrationClassName = useClassNames([
    'bg-neutral-5 text-neutral-4 rounded-[89px] border border-neutral-4 justify-center items-center shrink-0',
    'hover:bg-neutral-1 hover:text-neutral-6 hover:rounded-[72px]',
    'active:bg-neutral-1 active:rounded-[72px]',
    'focus-visible:outline focus-visible:border-white-100 focus-visible:border focus-visible:outline-offset-[3px]',
    'disabled:text-neutral-3 disabled:!bg-neutral-1 disabled:rounded-[72px] disabled:border-neutral-3',
  ]);

  const variantClassNames: variantBasedClassName = {
    primary: primaryClassName,
    primaryIconOnly: primaryIconOnlyClassName,
    secondary: secondaryClassName,
    secondaryIconOnly: secondaryIconOnlyClassName,
    underlined: underlineClassName,
    ghost: ghostClassName,
    warning: warningClassName,
    addToCart: addToCartClassName,
    removeFromCart: removeFromCartClassName,
    addToWishlist: addedToWishlistClassName,
    removeFromWishlist: removedFromWishlistClassName,
    home: homeClassName,
    registration: registrationClassName,
  };

  const sizeClassNames: { [key in ButtonSize]: string } = {
    xs: 'p-8',
    s: 'py-12 px-24',
    m: 'py-12 px-36',
    l: 'py-12 px-48',
    fit: 'p-0',
    full: 'py-12 px-24',
    icon: 'p-12',
    cart: 'p-10',
    home: 'p-4',
  };

  const buttonClassName = useClassNames([
    sizeClassNames[size as ButtonSize],
    'relative text-16 transition overflow-hidden',
    asSkeleton ? 'relative' : (variantClassNames[variant as ButtonVariant] as string),
    className,
    { 'flex gap-10 items-center': includesIcon },
    { 'cursor-not-allowed': !!loading || !!updated },
    {
      '!border-base-accent-1 disabled:bg-base-accent-1 disabled:border-base-accent-1':
        (!!loading || !!updated) && (variant === 'removeFromCart' || variant === 'addToCart'),
    },
  ]);

  return buttonClassName;
};

export default useButtonClassNames;
