import React, { useMemo } from 'react';
import NextLink from 'next/link';
import useClassNames from 'helpers/hooks/useClassNames';
import { resolveReferenceProps, resolveReferenceTarget } from 'helpers/reference';
import { Reference } from 'types/reference';

export type LinkVariant =
  | 'primary'
  | 'menu-header'
  | 'menu-item'
  | 'breadcrumb'
  | 'chip-active'
  | 'chip-inactive'
  | 'button-primary';

export interface LinkProps extends Omit<React.ComponentProps<typeof NextLink>, 'href' | 'key'> {
  link?: string | Reference;
  variant?: LinkVariant;
}

type VariantStyle = { [key in LinkVariant]: string };

const variantStyle: VariantStyle = {
  primary: 'text-14 lg:text-16 cursor-pointer text-neutral-500 hover:text-neutral-400',
  'menu-item': 'hover:underline hover:underline-offset-2',
  breadcrumb: 'text-14 font-medium text-primary-black',
  'menu-header': 'text-14 font-medium text-primary-black cursor-pointer',
  'chip-active':
    'rounded-[50px] border border-base-accent-1 bg-white px-24 py-12 text-14 font-bold leading-[16px] text-base-accent-1',
  'chip-inactive':
    'shadow-custom rounded-[50px] border border-transparent bg-white px-24 py-12 text-14 font-bold leading-[16px] active:border-base-accent-1 active:text-base-accent-1 active:shadow-none',
  'button-primary':
    'bg-neutral-6 text-neutral-5 rounded-[59px] justify-center items-center shrink-0 py-12 px-24 hover:bg-neutral-4 active:bg-neutral-4 focus-visible:outline focus-visible:outline-gray-700 border-gray-700 focus-visible:border focus-visible:outline-offset-[3px] disabled:bg-neutral-2 disabled:text-neutral-3',
};

const Link = ({
  link,
  children,
  className = '',
  variant,
  title = '',
  ...props
}: React.PropsWithChildren<LinkProps>) => {
  const linkUrl = useMemo(() => {
    if (!link) return '';
    if (typeof link === 'string') return link;

    return resolveReferenceTarget(link);
  }, [link]);

  const linkProps = useMemo(() => {
    if (!link || typeof link === 'string') return {};

    return resolveReferenceProps(link);
  }, [link]);

  const linkClassNames = useClassNames([variant ? variantStyle[variant] : '', className]);

  return (
    <NextLink {...props} {...linkProps} href={linkUrl ?? '#'} className={linkClassNames} title={title}>
      {children}
    </NextLink>
  );
};

export default Link;
