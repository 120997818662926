const screenSizes = {
  // TODO: replace these with the TW equivalent
  smallMobile: 320,
  mobile: 480,
  tablet: 744,
  desktop: 1024,
  mediumDesktop: 1280,
  largeDesktop: 1440,
  hugeDesktop: 1666,
  // Match tailwind.config.js
  sm: 480,
  md: 744,
  lg: 1024,
  xl: 1280,
  xxl: 1440,
  xxxl: 1666,
};

export const {
  smallMobile,
  mobile,
  tablet,
  desktop,
  mediumDesktop,
  largeDesktop,
  hugeDesktop,
  sm,
  md,
  lg,
  xl,
  xxl,
  xxxl,
} = screenSizes;
