import { ComponentProps, FC, ReactElement } from 'react';
import FeedbackIconLayer from './feedbackIconLayer';
import useButtonClassNames from './useButtonClassNames';
import Skeleton from '../skeleton';

export type ButtonVariant =
  | 'primary'
  | 'primaryIconOnly'
  | 'secondary'
  | 'secondaryIconOnly'
  | 'warning'
  | 'underlined'
  | 'ghost'
  | 'addToCart'
  | 'removeFromCart'
  | 'addToWishlist'
  | 'removeFromWishlist'
  | 'home'
  | 'registration';

export type ButtonSize = 'xs' | 's' | 'm' | 'l' | 'fit' | 'full' | 'icon' | 'cart' | 'home';

export type ButtonIconPosition = 'left' | 'middle' | 'right';

export interface ButtonProps extends Omit<ComponentProps<'button'>, 'key'> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  iconPosition?: ButtonIconPosition;
  icon?: ReactElement;
  loading?: boolean;
  updated?: boolean;
  asSkeleton?: boolean;
  'data-test'?: string;
}

const Button: FC<ButtonProps> = ({
  icon,
  iconPosition = 'left',
  updated,
  loading,
  children,
  className,
  disabled,
  asSkeleton = false,
  size = children ? 'full' : 'icon',
  variant = 'primary',
  ...props
}) => {
  const buttonClassName = useButtonClassNames({
    variant,
    className,
    includesIcon: !!icon && !!children,
    loading,
    updated,
    asSkeleton,
    size,
  });

  const showIcon =
    icon &&
    iconPosition === 'left' &&
    (variant === 'primary' ||
      variant === 'primaryIconOnly' ||
      variant === 'secondaryIconOnly' ||
      variant === 'addToCart' ||
      variant === 'removeFromCart' ||
      variant === 'addToWishlist' ||
      variant === 'removeFromWishlist' ||
      variant === 'home' ||
      variant === 'underlined' ||
      variant === 'registration');

  return (
    <button {...props} className={buttonClassName} disabled={disabled || loading}>
      {(loading || updated) && <FeedbackIconLayer loading={loading} variant={variant} />}
      {showIcon ? icon : null}
      {children}
      {icon && iconPosition == 'right' && icon}
      {asSkeleton && <Skeleton />}
    </button>
  );
};
export default Button;
